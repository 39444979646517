const emailBtn = document.getElementById('email-btn');
const emailInput = document.querySelector('input');
const form = document.querySelector('form');

emailInput.addEventListener('invalid', function (e) {
  e.target.setCustomValidity('Please enter the correct email format.');
});

const successNode = document.createElement('div');

emailBtn.addEventListener('click', function (e) {
  e.preventDefault();
  if (emailInput.value.trim() !== '') {
    const email = emailInput.value;
    fetch('https://kudos-email.herokuapp.com/', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // body data type must match "Content-Type" header
    })
      .then((data) => data.json())
      .then((data) => {
        successNode.innerText = data.text;
        successNode.style.color = 'red';
        form.parentNode.insertBefore(successNode, form.nextSibling);
      })
      .catch((err) => console.error(err));
  }
});

// mailchimp.setConfig({
//   apiKey: 'e5cfc9511fd823e9510b38461a3a241b-us10',
//   server: 'us10',
// });

// const event = {
//   name: 'JS Developers Meetup',
// };

// const footerContactInfo = {
//   company: 'Mailchimp',
//   address1: '675 Ponce de Leon Ave NE',
//   address2: 'Suite 5000',
//   city: 'Atlanta',
//   state: 'GA',
//   zip: '30308',
//   country: 'US',
// };

// const campaignDefaults = {
//   from_name: "Gettin' Together",
//   from_email: 'gettintogether@example.com',
//   subject: 'JS Developers Meetup',
//   language: 'EN_US',
// };

// async function run() {
//   const response = await mailchimp.lists.createList({
//     name: event.name,
//     contact: footerContactInfo,
//     permission_reminder: 'permission_reminder',
//     email_type_option: true,
//     campaign_defaults: campaignDefaults,
//   });

//   console.log(
//     `Successfully created an audience. The audience id is ${response.id}.`
//   );
// }

// run();
